adjustLogoWidth()
window.onresize = adjustLogoWidth



function adjustLogoWidth() {
    const claimSpan = document.querySelector('.home .claim-content .first span')
    const logo = document.querySelector('.logo-claim .logo-img')

    const temp = claimSpan.offsetWidth + 'px';
    logo.style.width = temp
    console.log('Temp: ', temp)
    console.log('Span width: ', claimSpan.offsetWidth)
    console.log('Logo width: ', logo.offsetWidth)
}